import WardSource from "../Slide1/Layers/WardSource";
import ReactMapGL from "react-map-gl";
import { FaUser } from "react-icons/fa";
import SchoolPoints from "../Slide1/CentralPanel/MapTypes/Points";
import Choropleth from "../Slide1/CentralPanel/MapTypes/Choropleth";
import { useState,useCallback,useRef, useEffect } from "react";
import SanitationChoropleth from "../Slide1/TopPanel/Legends/SanitationChoropleth";
const InterventionSites=()=>{
    const [hoveredFeatureId, setHoveredFeatureId] = useState(null);
  const mapRef = useRef(null);
  let style="mapbox://styles/mapbox/dark-v11"
  const [households,sethouseholds]=useState([])
  const [viewport, setViewport] = useState({
    latitude: -1.3367309,
    longitude: 35.3244957,
    zoom: 8.5,
    width: "100%",
    height: "100%",
  });

  const handleViewportChange = (newViewport) => {
    setViewport({ ...viewport, ...newViewport.viewState });
  };

  const moveEnd = () => {
    setViewport({
      latitude: -1.3367309,
      longitude: 35.3244957,
      zoom: 8.5,
      width: "100%",
      height: "100%",
    });
  };

  const handleHover = useCallback((event) => {
    const map = event.target;  // The map instance
    const { point } = event;   // The x, y point on the screen
  
    // Query features under the cursor at the given point
    const features = map.queryRenderedFeatures(point, {
      layers: ['vector-layer1']  // Specify the layer ID(s) you want to query
    });
  
    // If there are features at the point
    if (features.length > 0) {
      const hoveredFeature = features[0];  // Get the first feature (or handle multiple)
  
      // Check if the feature is different from the currently hovered feature
      if (hoveredFeature.id !== hoveredFeatureId) {
        // Reset the hover state of the previous feature
        if (hoveredFeatureId) {
          map.setFeatureState(
            { source: 'my-vector-source', id: hoveredFeatureId, sourceLayer: "N2S_wards-asr6x8" },
            { hover: false }
          );
        }
  
        // Set the hover state of the new feature
        setHoveredFeatureId(hoveredFeature.id);
        map.setFeatureState(
          { source: 'my-vector-source', id: hoveredFeature.id, sourceLayer: "N2S_wards-asr6x8" },
          { hover: true }
        );
      }
    } else if (hoveredFeatureId) {
      // If no feature is found but one was previously hovered, reset it
      map.setFeatureState(
        { source: 'my-vector-source', id: hoveredFeatureId, sourceLayer: "N2S_wards-asr6x8" },
        { hover: false }
      );
      setHoveredFeatureId(null);
    }
  }, [hoveredFeatureId]);
  const fetchHouseholds=()=>{
    fetch('/api/v1/getexamplehouseholds', {method:'GET'}).then(villages=>villages.json()).then(households=>{
        sethouseholds(households.households)
    })
}
useEffect(()=>{fetchHouseholds()},[])
  return (
    <div className="water-page-water-map">
      {/* <div className="water-page-water-map-details">
        <div className="water-page-water-map-heading">
          <h3>Example Sikawa Village Households</h3>
        </div>
        <div className="water-page-households-container">
          {households.length>0 && households.map((hh,index)=>(
            <div className="single-household" key={index}>
            <div className="single-household-name">
            <h4>Current Progress: {hh.currentStatus}</h4>
                <h4>Current Percent: {hh.currentPercent}%</h4>
            </div>
            <div className={`single-household-status`}>
                <div className="household-icon-holder">
                    <FaUser className="user-household"/>
                </div>
                <div className="household-icon-holder-opposite">
                    <h5>{hh.householdname}</h5>
                </div>
            </div>
        </div>
          ))
          }
        </div>
      </div> */}
      <div className="water-page-water-map-settings">
      <SanitationChoropleth/>
      </div>
        <ReactMapGL
      {...viewport}
      onMove={handleViewportChange}
    //   onMoveEnd={moveEnd}
      scrollZoom={false}
    //   dragPan={true}
      onMouseMove={handleHover}
      mapboxAccessToken={"pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw"}
      mapStyle={style}
      ref={mapRef}
    >
        <WardSource/> 
        <Choropleth pallete={'s-w-c'} datasource={"/api/v1/getwardsanitationchoropleth"} />
    </ReactMapGL>
    </div>

  )
}
export default InterventionSites