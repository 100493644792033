import React, { useState, useEffect,useContext } from 'react';
import { dashboardContext } from '../Dashboard';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const Numbers=()=>{
  const [numbers,setnumbers]=useState(null)
  const deployments=['KY07','KY09','KY013','KY016','KY018']
  const teams=['Construction Works','Community/School Sanitation','Water Governance']
  const [deployment, setdeployment]=useState(deployments[deployments.length-1])
  const [team,setteam]=useState('Construction Works')
  const {dashboardfilters,setdashboardfilters}=useContext(dashboardContext)
  const getNumbers=()=>{
    console.log('fetching numbers');
      fetch('api/v1/getDashboardNumbers',{method:'POST',credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(dashboardfilters)}).then(numbers=>numbers.json()).then(num=>{
        setnumbers(num.numbers)
      })
  }
  const teamChanged=()=>{
    setdashboardfilters({...dashboardfilters,unit:team})
  }
  const deploymentChanged=()=>{
    setdashboardfilters({...dashboardfilters,deployment:deployment})
  }
  useEffect(()=>{
      getNumbers()
  },[])
  useEffect(()=>{
   deploymentChanged()
},[deployment])
useEffect(()=>{
  teamChanged()
},[team])
  useEffect(()=>{
    getNumbers()
},[dashboardfilters])
    return ( 
        <div className='numbers-div'>
            <div className='number'>
            <h2>Projects' Progress - Gantt Chart</h2>
            <h5>
              Use this chart to track site progress in real time. Use the Well:Fair Companion tool to update the milestones which will be reflected here.
            </h5>
               <div className='dash-filters'>
                <div className='dash-filter'>
                  <FormControl  sx={{ m: 1, minWidth: 240 }} size="small">
                    <InputLabel id="demo-simple-select-label" sx={{fontFamily:'Cera Pro2',fontSize:'12px'}}>Deployment</InputLabel>
                    <Select
                    sx={{fontFamily:'Cera Pro2',fontSize:'12px'}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={deployment}
                      label="Deployment"
                    >
                      {deployments.map((dep,index)=>(
                        <MenuItem sx={{fontFamily:'Cera Pro2',fontSize:'12px'}} onClick={e=>setdeployment(dep)} value={dep} key={index}>{dep}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='dash-filter'>
                  <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                    <InputLabel id="demo-simple-select-label" sx={{fontFamily:'Cera Pro2',fontSize:'12px'}}>Project Unit</InputLabel>
                    <Select
                    sx={{fontFamily:'Cera Pro2',fontSize:'12px'}}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={team}
                      label="Project Unit"
                      onChange={teamChanged}
                    >
                      {teams.map((dep,index)=>(
                        <MenuItem sx={{fontFamily:'Cera Pro2',fontSize:'12px'}} value={dep} key={index} onClick={e=>setteam(dep)}>{dep}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
               </div>
            </div>
        </div>
    )
}
export default Numbers