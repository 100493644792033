import Heading from "../Paragraph/Heading"
import Paragraph from "../Paragraph/Paragraph"
import TopSpacing from "../Section/TopSpacing"
import Callout from "../Slide1/TopPanel/Callouts/Callout"
import InterventionSites from "./NeedsMap"
import { useState,useEffect } from "react"
import "./Needs.css"
const NeedsAssessment=()=>{
    const [sites,setsites]=useState([])
    const [students,setstudents]=useState(0)
    const [households,sethouseholds]=useState(0)
    const [population,setpopulation]=useState(0)
    const fetchSchools=()=>{
        fetch("/api/v1/getschoolpoints", { method: "GET", credentials: "include" })
          .then((points) => points.json())
          .then((schools) => {
            let totalsutudents=schools.schools.reduce((accum,curr)=>{
                let students=accum+curr.st
                return students
        },0)
          });
    }
    const fetchSites=()=>{
        fetch("/api/v1/getsites", { method: "POST", credentials: "include" })
          .then((sites) => sites.json())
          .then((sites) => {
            setsites(sites.sites);
            let totalsutudents=sites.sites.reduce((accum,curr)=>{
                let students=accum+curr.schoolBoys+curr.schoolGirls
                return students
        },0)
          });
    }
    const fetchCompleteSites=()=>{
        fetch("/api/v1/getcompledtesites", { method: "POST", credentials: "include" })
          .then((sites) => sites.json())
          .then((sites) => {
            let totalhouseholds=sites.sites.reduce((accum,curr)=>{
                let households=accum+(curr.households || 0) 
                return households
        },0)
        let totalsutudents=sites.sites.reduce((accum,curr)=>{
            let students=accum+curr.schoolBoys+curr.schoolGirls
            return students
    },0)
            setstudents(totalsutudents)
            sethouseholds(totalhouseholds)
            setpopulation(totalhouseholds*5)
          });
    }
    useEffect(()=>{
        fetchSchools()
        fetchSites()
        fetchCompleteSites()
    },[])
    useEffect(()=>{},[sites])
    return (
        <div className="water-slide-div">
            <TopSpacing/>
            <Heading title={"Schools' Needs Assessment"}/>
            <Paragraph content={"This page shows the results from the needs assessment exercise which took place in schools within Narok West and Transmara West"}/>
            <div className="water-page-callout-div">
            <Callout datasource={sites.length} description={"Total sites we have intervened in for Narok West and Transmara West"}/>
            <Callout datasource={students} description={"Total Students benefiting from the interventions"}/>
            <Callout datasource={households} description={"Total Households accessing water through our interventions"}/>
            <Callout datasource={population} description={"People reached with water through our interventions"}/>
            </div>
            <InterventionSites/>
        </div>
    )
}
export default NeedsAssessment