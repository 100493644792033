import React, { useState, useEffect,useContext} from 'react';
import { useParams } from 'react-router';
import { Gantt} from 'gantt-task-react';
import { dashboardContext } from './Dashboard';
import {useHistory} from 'react-router-dom'
import BounceLoader from "react-spinners/BounceLoader"
import "gantt-task-react/dist/index.css";
const GanttChart=({})=>{
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    //   });
    const {dashboardfilters,setdashboardfilters}=useContext(dashboardContext)
    const params= useParams()
    const [tasks,settasks]=useState([])
    const [openDialog,setopenDialog]=useState(false)
    const [reason,setreason]=useState(null)
    const [loading,setloading]=useState(false)
    const [task,settask]=useState(null)
    const [siteid,setsiteid]=useState(null)
    const [view,setview]=useState('Month')
    async function getSiteCompletion(siteid){
      if(dashboardfilters.unit=='Construction Works'){
        return fetch(`/api/v1/sitesections/${siteid}`,{method:'GET'}).then(site=>site.json()).then(site=>{
          return site.sects
        })
      }
      if(dashboardfilters.unit=='Water Governance'){
        return fetch(`/api/v1/govsections/${siteid}`,{method:'GET'}).then(site=>site.json()).then(site=>{
          return site.sects
        })
      }
      if(dashboardfilters.unit=='Community/School Sanitation'){
        return fetch(`/api/v1/cltssections/${siteid}`,{method:'GET'}).then(site=>site.json()).then(site=>{
          return site.sects
        })
      }
    }
    const fetchSites = async () => {
      try {
        setloading(true)
          const sitesResponse = await fetch(`/api/v1/getsites`,{method:'POST',credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(dashboardfilters)});
          const sects = await sitesResponse.json();
          let thetasks = [];
          let mysects = sects.sites.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
          // Function to retrieve site completion asynchronously
          const getProgress = async (siteId) => {
              // Assuming getSiteCompletion returns a promise
              return await getSiteCompletion(siteId);
          };
          // Fetch progress for all sites concurrently
          const progressPromises = mysects.map(section => getProgress(section._id));
          const progressResults = await Promise.all(progressPromises);
          // Create tasks in parallel using map
          thetasks = await Promise.all(mysects.map(async (section, index) => {
              let task = {
                  start: new Date(section.startDate),
                  end: new Date(section.endDate),
                  name: `${section.sitename}(${section.intervention})`,
                  id: section.sitename,
                  taskid: section._id,
                  type: 'task',
                  progress: Math.floor(progressResults[index]), // Use the corresponding progress result
                  project: section.deployment,
                  styles: { progressSelectedColor: '#ff9e0d', progressColor: '#359c68' }
              };
              return task;
          }));
  
          // Concatenate tasks arrays
          let currenttasks = [...tasks];
          let finalarray = currenttasks.concat(thetasks);
          settasks(thetasks);
          setloading(false)
      } catch (error) {
          console.error("Error fetching sites:", error);
      }
  };
    let navigate = useHistory();
    const setSite=(task)=>{
     navigate.push(`/water-access/site/${task.taskid}/${task.id}`)
    }
    
    
    useEffect(()=>{
      fetchSites()
    },[])
    useEffect(()=>{
      settasks([]);
      fetchSites()
    },[dashboardfilters])
    return ( 
        <>
        {loading && <p><BounceLoader color='#3fae89'
          loading={loading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"/></p> }
        {tasks.length>0 && 
                    <Gantt tasks={tasks}
                    ganttHeight={600}
                    viewMode={'Month'}
                    fontFamily='Cera Pro2'
                    fontSize='11px'
                    onClick={setSite}
                    rowHeight={35}/>
                    }
        </>

    )
}
export default GanttChart